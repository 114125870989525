<script>
import { mapState } from 'vuex'
import SectionComponent from '@/components/resource_library/course_package/check/section'
export default {
  components: {
    SectionComponent,
  },
  computed: {
    ...mapState({
      // courseId: state => state.course_package.id,
      coursePackage: state => state.course_package.data,
      // coursePackageList: state => state.course_package.packageList,
    }),
    sectionList () {
      const { coursePackage } = this
      if (!coursePackage) {
        return []
      }
      return coursePackage.chapters
    }
  }
}
</script>

<template>
<div
  class="section_list bbox_wrapper"
>
  <div
    class="list_group d-flex align-center flex-column"
  >
    <template
      v-for="(item, index) in sectionList"
    >
      <SectionComponent
        :key="index"
        class="section_wrapper"
        :section="item"
      ></SectionComponent>
    </template>
  </div>
</div>
</template>

<style scoped lang="scss">
.section_list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  .list_group {
    width: 100%;
    height: 100%;
    overflow: auto;
    .section_wrapper {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
