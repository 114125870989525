var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "section_wrapper bbox_wrapper d-flex align-center flex-column flex-shrink-0",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "section_info_group un_sel d-flex align-center justify-space-between cp",
          class: {
            close: _vm.showTaskList,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.changeShowTaskList.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "name_group d-flex align-center" }, [
            _c("div", { staticClass: "icon" }),
            _c("div", { staticClass: "name" }, [
              _vm._v("\n        " + _vm._s(_vm.section.title) + "\n      "),
            ]),
          ]),
          _c("div", { staticClass: "symbol" }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTaskList,
              expression: "showTaskList",
            },
          ],
          staticClass: "task_list d-flex align-center flex-wrap",
        },
        [
          _vm._l(_vm.section.tasks, function (item, index) {
            return [
              _c("TaskComponent", {
                key: index,
                staticClass: "flex-shrink-0 task cp",
                attrs: { "task-info": item, "show-select": false },
                nativeOn: {
                  click: function ($event) {
                    return _vm.touchTask(item)
                  },
                },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }