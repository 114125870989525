var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "choice_task_task bbox_wrapper d-flex align-center flex-column",
    },
    [
      _vm.showSelect
        ? _c("div", { staticClass: "select_group" }, [
            _c("div", {
              staticClass: "select",
              class: {
                active: _vm.selected,
              },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "info_group d-flex align-start justify-space-between flex-column",
        },
        [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n      " + _vm._s(_vm.taskInfo.name) + "\n    "),
          ]),
          _c("div", { staticClass: "time_group" }, [
            _vm._v(
              "\n       " +
                _vm._s(
                  _vm.$moment(_vm.taskInfo.createTime).format("YYYY/MM/DD")
                ) +
                "\n    "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "user_group d-flex align-center justify-space-between" },
        [
          _c("div", { staticClass: "user_avatar" }, [
            _c("div", {
              staticClass: "avatar",
              style: {
                backgroundImage: `url(${_vm.$avatar_url}${_vm.taskInfo.updateuserthumbnail}@!small200)`,
              },
            }),
          ]),
          _c("div", { staticClass: "tag_group" }, [
            _vm.taskInfo.taskType === 2
              ? _c("div", { staticClass: "tag teach" }, [
                  _vm._v("\n        教\n      "),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }