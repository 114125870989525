var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "section_list bbox_wrapper" }, [
    _c(
      "div",
      { staticClass: "list_group d-flex align-center flex-column" },
      [
        _vm._l(_vm.sectionList, function (item, index) {
          return [
            _c("SectionComponent", {
              key: index,
              staticClass: "section_wrapper",
              attrs: { section: item },
            }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }