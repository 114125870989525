<script>
import TaskInfo from '@/models/task/task_info'
export default {
  props: {
    taskInfo: {
      type: TaskInfo,
      default: undefined
    },
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    },
    showSelect: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selected () {
      if (this.selectList && this.selectList.length) {
        const include = this.selectList.find((unit) => {
          return unit.id === this.taskInfo.id
        })
        if (include) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<template>
<div
  class="choice_task_task bbox_wrapper d-flex align-center flex-column"
>
  <div
    v-if="showSelect"
    class="select_group"
  >
    <div
      class="select"
      :class="{
        active: selected
      }"
    >

    </div>
  </div>
  <div
    class="info_group d-flex align-start justify-space-between flex-column"
  >
    <div
      class="name"
    >
      {{ taskInfo.name }}
    </div>
    <div
      class="time_group"
    >
      &nbsp;{{ $moment(taskInfo.createTime).format('YYYY/MM/DD') }}
    </div>
  </div>
  <div
    class="user_group d-flex align-center justify-space-between"
  >
    <div
      class="user_avatar"
    >
      <div
        class="avatar"
        :style="{
          backgroundImage: `url(${ $avatar_url }${ taskInfo.updateuserthumbnail }@!small200)`
        }"
      ></div>
    </div>
    <div
      class="tag_group"
    >
      <div
        v-if="taskInfo.taskType === 2"
        class="tag teach"
      >
        教
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.choice_task_task {
  width: 160px;
  height: 142px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
  border-radius: 10px;
  border: 1px solid #E8EEFF;
  position: relative;
  .select_group {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: 9;
    .select {
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/course_package/select.png);
      &.active {
        background-image: url(~@/assets/images/course_package/selected.png);
      }
    }
  }
  .info_group {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 11px;
    .name {
      @include multi_line_intercept(2);
      font-size: 12px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
    .time_group {
      border-left: 4px solid #E5E5E5;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      text-align: left;
      font-style: normal;
    }
  }
  .user_group {
    position: relative;
    z-index: 1;
    height: 47px;
    width: 100%;
    padding: 0 11px;
    border-top: 4px solid #f8f8f8;
    .user_avatar {
      .avatar {
        @include background;
        width: 20px;
        height: 20px;
        border-radius: 2px;
      }
    }
    .tag_group {
      .tag {
        width: 20px;
        height: 20px;
        text-align: center;
        font-size: 13px;
        border-radius: 4px;
        &.teach {
          background: rgba(255,114,0,0.08);
          color: #FF9D4C;
        }
      }
    }
  }
}
</style>
