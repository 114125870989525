<script>
import CourseSection from '@/models/cource_package/course_section'
import TaskComponent from '@/components/resource_library/course_package/choice_task/task'
export default {
  components: {
    TaskComponent,
  },
  props: {
    section: {
      type: CourseSection,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showTaskList: true,
    }
  },
  methods: {
    changeShowTaskList () {
      this.showTaskList = !this.showTaskList
    },
    touchTask (task) {
      this.$api.moduleTask.openTask({
        taskId: task.id,
        config: {
          view: true,
          // share: true,
          // view: true,
          // teach: true,
        }})
    },
  }
}
</script>

<template>
<div
  class="section_wrapper bbox_wrapper d-flex align-center flex-column flex-shrink-0"
>
  <div
    class="section_info_group un_sel d-flex align-center justify-space-between cp"
    :class="{
      close: showTaskList
    }"
    @click.stop="changeShowTaskList"
  >
    <div
      class="name_group d-flex align-center"
    >
      <div
        class="icon"
      ></div>
      <div
        class="name"
      >
        {{ section.title }}
      </div>
    </div>
    <div
      class="symbol"
    ></div>
  </div>
  <div
    v-show="showTaskList"
    class="task_list d-flex align-center flex-wrap"
  >
    <template
      v-for="(item, index) in section.tasks"
    >
      <TaskComponent
        :key="index"
        class="flex-shrink-0 task cp"
        :task-info="item"
        :show-select="false"
        @click.native="touchTask(item)"
      ></TaskComponent>
    </template>
  </div>
</div>
</template>

<style scoped lang="scss">
.section_wrapper {
  color: #333333;
  .section_info_group {
    width: 100%;
    height: 40px;
    background: #F4F7FA;
    border-radius: 10px;
    padding: 0 20px;
    .name_group {
      .icon {
        width: 14px;
        height: 14px;
        background-image: url(~@/assets/images/course_package/add_section.png);
        margin-right: 8px;
      }
      .name {
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
    }
    .symbol {
      width: 12px;
      height: 7px;
      background-image: url(~@/assets/images/course_package/close_task_list.png);
    }
    &.close {
      .symbol {
        background-image: url(~@/assets/images/course_package/show_task_list.png);
      }
    }
  }
  .task_list {
    width: 100%;

    //padding: 20px;
    .task {
      margin-top: 15px;
      margin-right: 10px;
    }
  }
}
</style>
